import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import ScriptTag from 'react-script-tag';
import { createStructuredSelector } from 'reselect';
// common
import { makeSelectGoogleApiKey } from './common/selectors';
// helpers/constants
import * as G from './helpers';
import * as GC from './constants';
// ui
import { ListWrapper } from './ui';
// features
import Icons from './features/icons';
import Login from './features/auth/login';
import BranchList from './features/branch';
import Imports from './features/new-import';
import UserComponent from './features/user';
import FleetMap from './features/fleet-map';
import RateShop from './features/rate-shop';
import RolesComponent from './features/role';
import FleetList from './features/fleet-list';
import LoadBoard from './features/load-board';
import NewDOComponent from './features/new-do';
import Dashboards from './features/dashboards';
import OAuthPopup from './features/oauth-popup';
import NALLogin from './features/auth/nal-login';
import MainLayout from './components/main-layout';
import IntlComponent from './features/intl/index';
import StylingComponent from './features/styling';
import PublicCLOList from './features/public-clo';
import ReportsList from './features/report-format';
import ChargeReport from './features/charge-report';
import QuotesListComponent from './features/quotes';
import CarrierEdiList from './features/carrier-edi';
import ImportReport from './features/import-report';
import ChartsListComponent from './features/charts';
import DashboardComponent from './features/dashboard';
import UserSettingsComponent from './features/profile';
import IftaListComponent from './features/ifta-report';
import CarriersListComponent from './features/carrier';
import NewPassword from './features/auth/new-password';
import InspectionsReport from './features/inspections';
import CarrierProfile from './features/carrier-profile';
import CustomerPortal from './features/customer-portal';
import TemplateReport from './features/template/report';
import LiteNewDOComponent from './features/lite-new-do';
import DriverPayrollsComponent from './features/payroll';
import SequencesListComponent from './features/sequence';
import WorkOrderList from './features/work-order/report';
import ComponentsStory from './features/components-story';
import DriverInvoiceList from './features/invoice/driver';
import VendorInvoiceList from './features/invoice/vendor';
import NewDOQuoteComponent from './features/new-do-quote';
import FuelCardListComponent from './features/fuel-cards';
import ReferenceListComponent from './features/reference';
import DispatchPlanner from './features/dispatch-planner';
import DispatchGroupList from './features/dispatch-group';
import DriversCardComponent from './features/drivers-card';
import ResetPassword from './features/auth/reset-password';
import CarrierInvoiceList from './features/invoice/carrier';
import LosSubscriptionList from './features/los-subscription';
import EDIIntegrationReport from './features/edi-integration';
import CustomerInvoiceList from './features/invoice/customer';
import AvailableDriverList from './features/available-driver';
import CarrierContractList from './features/contracts/carrier';
import CarrierPortalComponent from './features/carrier-portal';
import TollChargesListComponent from './features/toll-charges';
import BoshLosRedirect from './features/auth/bosh-los-redirect';
import BoshLosCallback from './features/auth/bosh-los-callback';
import NotificationListComponent from './features/notification';
import LoadListComponent from './features/dispatch-report/load';
import TemplateInspection from './features/template/inspection';
import GeoFencingLocation from './features/geo-fencing-location';
import CustomerContractList from './features/contracts/customer';
import AverageFuelPriceList from './features/average-fuel-price';
import RouteListComponent from './features/dispatch-report/route';
import EditBranch from './features/branch/components/edit-branch';
import OrderListComponent from './features/dispatch-report/order';
import NewPasswordMobile from './features/auth/new-password-mobile';
import EditTruck from './features/fleet/truck/components/edit-form';
import EditRoleComponent from './features/role/components/edit-role';
import PrivacyPolicy from './features/auth/legal-info/privacy-policy';
import EditDriver from './features/fleet/driver/components/edit-form';
import DashboardsPayrollComponent from './features/dashboards-payroll';
import LoadPlannerTemplateList from './features/load-planner-template';
import NormalizeCurrencyList from './features/normalize-currency-list';
import DispatchPlannerEvents from './features/dispatch-planner-events';
import CarrierRatingReportList from './features/carrier-rating-report';
import TrailerEdit from './features/fleet/trailer/components/edit-form';
import CustomerMasterInvoicesComponent from './features/master-invoice';
import CommissionAssignmentList from './features/commission-assignment';
import RouteMassCreateReport from './features/route-mass-create-report';
import ContainerTypesComponent from './features/template/container-type';
import CreateRoleComponent from './features/role/components/create-role';
import ChartFormComponent from './features/charts/components/chart-form';
import ServiceVendorInvoiceList from './features/invoice/service-vendor';
import CarrierResponsePage from './features/carrier-portal/response-page';
import RatingGeoFencingZoneList from './features/geo-fencing-zone/rating';
import ReportGeoFencingZoneList from './features/geo-fencing-zone/report';
import EditFleetVendor from './features/fleet/vendor/components/edit-form';
import CarrierEditComponent from './features/carrier/components/edit-form';
import LoadDetailsNewComponent from './features/dispatch-details-new/load';
import OrderDetailsNewComponent from './features/dispatch-details-new/order';
import CarrierResponseAccept from './features/carrier-portal/carrier-accept';
import CarrierResponseDecline from './features/carrier-portal/carrier-decline';
import SplashScreenComponent from './features/styling/components/splash-screen';
import TermsAndConditions from './features/auth/legal-info/terms-and-conditions';
import CarrierContractDetails from './features/carrier-profile/contracts/carrier';
import CustomerContractDetails from './features/carrier-profile/contracts/customer';
import UserTermsAndConditions from './features/auth/legal-info/user-terms-and-conditions';
import NewPasswordMobileSuccessPage from './features/auth/new-password-mobile-success-page';
// new dispatch board
import RouteListLoad from './features/dispatch-board-new/load';
import RouteListOrder from './features/dispatch-board-new/order';
// configs
import ConfigUIComponent from './features/configurations/config-pages/ui';
import ConfigAuditPage from './features/configurations/config-pages/audit';
import ConfigTelComponent from './features/configurations/config-pages/tel';
import ConfigCloComponent from './features/configurations/config-pages/clo';
import ConfigTruckComponent from './features/configurations/config-pages/truck';
import ConfigDriverComponent from './features/configurations/config-pages/driver';
import ConfigRatingComponent from './features/configurations/config-pages/rating';
import ConfigMasterComponent from './features/configurations/config-pages/optional';
import ConfigTrailerComponent from './features/configurations/config-pages/trailer';
import ConfigGeneralComponent from './features/configurations/config-pages/general';
import ConfigCarrierComponent from './features/configurations/config-pages/carrier';
import ConfigInvoicesComponent from './features/configurations/config-pages/invoice';
import ConfigPasswordComponent from './features/configurations/config-pages/password';
import ConfigMobileAppComponent from './features/configurations/config-pages/mobileapp';
import ConfigTemplatesComponent from './features/configurations/config-pages/templates';
import ConfigClaimManagementComponent from './features/configurations/config-pages/claim';
import ConfigIntegrationComponent from './features/configurations/config-pages/integration';
import ConfigFleetGeneralComponent from './features/configurations/config-pages/fleet-general';
import ConfigCommunicationComponent from './features/configurations/config-pages/communication';
import ConfigServiceVendorComponent from './features/configurations/config-pages/service-vendor';
import ConfigTaskManagementComponent from './features/configurations/config-pages/task-management';
// service vendor
import ServiceVendorList from './features/service-vendor';
import ServiceVendorProfile from './features/service-vendor/components/edit-form';
// task-management
import TaskManagementBoard from './features/task-management/board';
import TaskManagementReport from './features/task-management/report';
import TaskManagementBoardList from './features/task-management/board-list';
// fleet-profile
import FleetProfileTruck from './features/fleet-profile/truck';
import FleetProfileVendor from './features/fleet-profile/vendor';
import FleetProfileDriver from './features/fleet-profile/driver';
import FleetProfileTrailer from './features/fleet-profile/trailer';
// claim-management
import ClaimManagementReport from './features/claim-management/report';
import ClaimManagementClaimDetails from './features/claim-management/details';
//////////////////////////////////////////////////

export const RouteWithSubRoutes = (route: Object) => (
  <Route
    path={route.path}
    render={(props: Object) => <route.component {...props} routes={route.routes} />}
  />
);

const startMouseFlow = () => {
  window._mfq = window._mfq || [];

  return '//cdn.mouseflow.com/projects/90f2fef0-3549-408a-8856-8f627e775da9.js';
};

const AppRoutes = (props: Object) => (
  <div>
    {
      props.routes.map((route: Object, i: number) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))
    }
    <ScriptTag
      type='text/javascript'
      src={G.makeGoogleAPILinkWithKey(props.googleAPIKey)}
    />
    <ScriptTag type='text/javascript' src={startMouseFlow()} />
  </div>
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  googleAPIKey: makeSelectGoogleApiKey(state),
}));

const App = connect(mapStateToProps)(AppRoutes);

const fleetRoutes = [
  {
    component: FleetList,
    path: GC.ROUTE_PATH_FLEET_DRIVERS_LIST,
  },
  {
    component: EditDriver,
    path: GC.ROUTE_PATH_FLEET_DRIVERS_EDIT,
  },
  {
    component: FleetList,
    path: GC.ROUTE_PATH_FLEET_TRAILERS_LIST,
  },
  {
    component: TrailerEdit,
    path: GC.ROUTE_PATH_FLEET_TRAILERS_EDIT,
  },
  {
    component: FleetList,
    path: GC.ROUTE_PATH_FLEET_TRUCKS_LIST,
  },
  {
    component: EditTruck,
    path: GC.ROUTE_PATH_FLEET_TRUCKS_EDIT,
  },
  {
    component: FleetMap,
    path: GC.ROUTE_PATH_FLEET_MAP,
  },
  {
    component: FleetList,
    path: GC.ROUTE_PATH_FLEET_VENDOR_LIST,
  },
  {
    component: FleetList,
    path: GC.ROUTE_PATH_FLEET_EQUIPMENT_SERVICE_LIST,
  },
  {
    component: FleetList,
    path: GC.ROUTE_PATH_FLEET_SHARED_COMPONENT_LIST,
  },
  {
    component: EditFleetVendor,
    path: GC.ROUTE_PATH_FLEET_VENDOR_EDIT,
  },
  {
    component: FleetList,
    path: GC.ROUTE_PATH_FLEET_DRIVER_PAYROLL_ACCESSORIAL_LIST,
  },
  {
    component: FleetList,
    path: GC.ROUTE_PATH_FLEET_VENDOR_PAYROLL_ACCESSORIAL_LIST,
  },
  {
    component: FleetList,
    path: GC.ROUTE_PATH_FLEET_SERVICE_ISSUES_LIST,
  },
  {
    component: GeoFencingLocation,
    path: GC.ROUTE_PATH_GEO_FENCING_LOCATION,
  },
];

const Fleet = () => (
  <ListWrapper p='0' bgColor={G.getTheme('pages.layOutBgColor')}>
    {
      fleetRoutes.map((route: Object, i: number) => <RouteWithSubRoutes key={i} {...route} />)
    }
  </ListWrapper>
);

const configurationsRoutes = [
  {
    component: ConfigPasswordComponent,
    path: GC.ROUTE_PATH_CONFIG_PASSWORD,
  },
  {
    component: ConfigTelComponent,
    path: GC.ROUTE_PATH_CONFIG_TEL,
  },
  {
    component: ConfigCloComponent,
    path: GC.ROUTE_PATH_CONFIG_CLO,
  },
  {
    component: ConfigGeneralComponent,
    path: GC.ROUTE_PATH_CONFIG_GENERAL,
  },
  {
    component: ConfigMasterComponent,
    path: GC.ROUTE_PATH_CONFIG_OPTIONAL,
  },
  {
    component: ConfigTemplatesComponent,
    path: GC.ROUTE_PATH_CONFIG_TEMPLATES,
  },
  {
    component: ConfigDriverComponent,
    path: GC.ROUTE_PATH_CONFIG_DRIVER,
  },
  {
    component: ConfigFleetGeneralComponent,
    path: GC.ROUTE_PATH_CONFIG_FLEET_GENERAL,
  },
  {
    component: ConfigTrailerComponent,
    path: GC.ROUTE_PATH_CONFIG_TRAILER,
  },
  {
    component: ConfigTruckComponent,
    path: GC.ROUTE_PATH_CONFIG_TRUCK,
  },
  {
    component: ConfigInvoicesComponent,
    path: GC.ROUTE_PATH_CONFIG_INVOICES,
  },
  {
    component: ConfigRatingComponent,
    path: GC.ROUTE_PATH_CONFIG_RATING,
  },
  {
    component: ConfigCommunicationComponent,
    path: GC.ROUTE_PATH_CONFIG_COMMUNICATION,
  },
  {
    component: ConfigCarrierComponent,
    path: GC.ROUTE_PATH_CONFIG_CARRIER,
  },
  {
    component: ConfigServiceVendorComponent,
    path: GC.ROUTE_PATH_CONFIG_SERVICE_VENDOR,
  },
  {
    component: ConfigIntegrationComponent,
    path: GC.ROUTE_PATH_CONFIG_INTEGRATION,
  },
  {
    component: ConfigUIComponent,
    path: GC.ROUTE_PATH_CONFIG_UI,
  },
  {
    component: ConfigMobileAppComponent,
    path: GC.ROUTE_PATH_CONFIG_MOBILEAPP,
  },
  {
    component: ConfigAuditPage,
    path: GC.ROUTE_PATH_CONFIG_AUDIT,
  },
  {
    component: ConfigTaskManagementComponent,
    path: GC.ROUTE_PATH_CONFIG_TASK_MANAGEMENT,
  },
  {
    component: ConfigClaimManagementComponent,
    path: GC.ROUTE_PATH_CONFIG_CLAIM_MANAGEMENT,
  },
];

const ConfigurationsComponent = () => (
  <div>
    {
      configurationsRoutes.map((route: Object, i: number) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))
    }
  </div>
);

const invoiceRoutes = [
  {
    component: DriverInvoiceList,
    path: GC.ROUTE_PATH_DRIVER_INVOICES_LIST,
  },
  {
    component: VendorInvoiceList,
    path: GC.ROUTE_PATH_VENDOR_INVOICES_LIST,
  },
  {
    component: CustomerInvoiceList,
    path: GC.ROUTE_PATH_CUSTOMER_INVOICES_LIST,
  },
  {
    component: CarrierInvoiceList,
    path: GC.ROUTE_PATH_CARRIER_INVOICES_LIST,
  },
  {
    component: ServiceVendorInvoiceList,
    path: GC.ROUTE_PATH_SERVICE_VENDOR_INVOICE_LIST,
  },
];

const Invoice = () => (
  <ListWrapper bgColor={G.getTheme('pages.layOutBgColor')}>
    {
      invoiceRoutes.map((route: Object, i: number) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))
    }
  </ListWrapper>
);

const templateRoutes = [
  {
    component: TemplateReport,
    path: GC.ROUTE_PATH_TEMPLATES_DO_LIST,
  },
  {
    component: ContainerTypesComponent,
    path: GC.ROUTE_PATH_CONTAINER_TYPE_LIST,
  },
  {
    component: TemplateReport,
    path: GC.ROUTE_PATH_TEMPLATE_CONTAINER_LIST,
  },
  {
    component: TemplateReport,
    path: GC.ROUTE_PATH_TEMPLATES_ROUTE_LIST,
  },
  {
    component: TemplateReport,
    path: GC.ROUTE_PATH_TEMPLATES_COMPENSATION_LIST,
  },
  {
    component: TemplateInspection,
    path: GC.ROUTE_PATH_TEMPLATES_INSPECTION,
  },
];

const TemplateComponent = () => (
  <ListWrapper bgColor={G.getTheme('pages.layOutBgColor')}>
    {
      templateRoutes.map((route: Object, i: number) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))
    }
  </ListWrapper>
);

export default [
  {
    component: Login,
    path: GC.ROUTE_PATH_LOGIN,
  },
  {
    component: NALLogin,
    path: GC.ROUTE_PATH_NAL_LOGIN,
  },
  {
    component: NewPassword,
    path: GC.ROUTE_PATH_NEW_PASSWORD,
  },
  {
    component: ResetPassword,
    path: GC.ROUTE_PATH_RESET_PASSWORD,
  },
  {
    component: TermsAndConditions,
    path: GC.ROUTE_PATH_TERMS_AND_CONDITIONS,
  },
  {
    component: PrivacyPolicy,
    path: GC.ROUTE_PATH_PRIVACY_POLICY,
  },
  {
    component: NewPasswordMobile,
    path: GC.ROUTE_PATH_NEW_PASSWORD_MOBILE,
  },
  {
    component: NewPasswordMobileSuccessPage,
    path: GC.ROUTE_PATH_NEW_PASSWORD_MOBILE_SUCCESS_PAGE,
  },
  {
    component: CarrierResponsePage,
    path: GC.ROUTE_PATH_CARRIERS_RESPONSE,
  },
  {
    component: CarrierResponseAccept,
    path: GC.ROUTE_PATH_CARRIERS_ACCEPT,
  },
  {
    component: CarrierResponseDecline,
    path: GC.ROUTE_PATH_CARRIERS_DECLINE,
  },
  {
    component: CarrierResponseAccept,
    path: GC.ROUTE_PATH_ORDER_QUOTE_ACCEPT,
  },
  {
    component: CarrierResponseDecline,
    path: GC.ROUTE_PATH_ORDER_QUOTE_DECLINE,
  },
  {
    component: CarrierResponseAccept,
    path: GC.ROUTE_PATH_CARRIER_QUOTE_ACCEPT,
  },
  {
    component: CarrierResponseDecline,
    path: GC.ROUTE_PATH_CARRIER_QUOTE_DECLINE,
  },
  {
    component: CarrierPortalComponent,
    path: GC.ROUTE_PATH_CARRIERS_ADD_MESSAGE_PAGE,
  },
  {
    component: BoshLosRedirect,
    path: GC.ROUTE_PATH_BOSH_LOS_REDIRECT,
  },
  {
    component: BoshLosCallback,
    path: GC.ROUTE_PATH_BOSH_LOS_CALLBACK,
  },
  {
    exact: true,
    component: App,
    path: GC.ROUTE_PATH_HOME,
    routes: [
      {
        component: MainLayout,
        path: GC.ROUTE_PATH_HOME,
      },
      {
        path: GC.ROUTE_PATH_PROFILE,
        component: UserSettingsComponent,
      },
      {
        component: UserComponent,
        path: GC.ROUTE_PATH_USERS_LIST,
      },
      {
        component: RolesComponent,
        path: GC.ROUTE_PATH_ROLES_LIST,
      },
      {
        component: UserSettingsComponent,
        path: GC.ROUTE_PATH_USERS_SETTINGS,
      },
      {
        component: EditRoleComponent,
        path: GC.ROUTE_PATH_ROLES_EDIT,
      },
      {
        path: GC.ROUTE_PATH_ROLES_NEW,
        component: CreateRoleComponent,
      },
      {
        component: BranchList,
        path: GC.ROUTE_PATH_BRANCH_LIST,
      },
      {
        component: EditBranch,
        path: GC.ROUTE_PATH_BRANCH_EDIT,
      },
      {
        component: TemplateReport,
        path: GC.ROUTE_PATH_ITEMS_LIST,
      },
      {
        path: GC.ROUTE_PATH_SEQUENCES_LIST,
        component: SequencesListComponent,
      },
      {
        component: ReferenceListComponent,
        path: GC.ROUTE_PATH_REFERENCES_LIST,
      },
      {
        component: TemplateReport,
        path: GC.ROUTE_PATH_LOCATIONS_LIST,
      },
      {
        component: Fleet,
        path: GC.ROUTE_PATH_FLEET,
      },
      {
        component: NewDOComponent,
        path: GC.ROUTE_PATH_DO_NEW,
      },
      {
        path: GC.ROUTE_PATH_DO_QUOTE,
        component: NewDOQuoteComponent,
      },
      {
        component: LiteNewDOComponent,
        path: GC.ROUTE_PATH_LITE_DO_NEW,
      },
      {
        component: SplashScreenComponent,
        path: GC.ROUTE_PATH_SPLASH_SCREEN_SETTINGS,
      },
      {
        path: GC.ROUTE_PATH_STYLING,
        component: StylingComponent,
      },
      {
        component: ReportsList,
        path: GC.ROUTE_PATH_REPORTS_LIST,
      },
      {
        path: GC.ROUTE_PATH_QUOTE,
        component: QuotesListComponent,
      },
      {
        component: QuotesListComponent,
        path: GC.ROUTE_PATH_ORDER_QUOTES,
      },
      {
        path: GC.ROUTE_PATH_CONFIG,
        component: ConfigurationsComponent,
      },
      {
        path: GC.ROUTE_PATH_CLO,
        component: OrderDetailsNewComponent,
      },
      {
        path: GC.ROUTE_PATH_TEL,
        component: LoadDetailsNewComponent,
      },
      {
        component: Invoice,
        path: GC.ROUTE_PATH_DRIVER_INVOICES,
      },
      {
        component: TemplateComponent,
        path: GC.ROUTE_PATH_TEMPLATES,
      },
      {
        component: DispatchPlanner,
        path: GC.ROUTE_PATH_ROUTE_BUILDER,
      },
      {
        component: DispatchPlannerEvents,
        path: GC.ROUTE_PATH_LOAD_PLANNER,
      },
      {
        path: GC.ROUTE_PATH_ANALYTICS,
        component: DashboardComponent,
      },
      {
        component: Dashboards,
        path: GC.ROUTE_PATH_DASHBOARDS,
      },
      {
        path: GC.ROUTE_PATH_DASHBOARDS_PAYROLL,
        component: DashboardsPayrollComponent,
      },
      {
        component: OrderListComponent,
        path: GC.ROUTE_PATH_CLO_REPORT_LIST,
      },
      {
        component: LoadListComponent,
        path: GC.ROUTE_PATH_TEL_REPORT_LIST,
      },
      {
        component: RouteListComponent,
        path: GC.ROUTE_PATH_ROUTE_REPORT_LIST,
      },
      {
        component: ChartsListComponent,
        path: GC.ROUTE_PATH_CHARTS_LIST,
      },
      {
        component: ChartFormComponent,
        path: GC.ROUTE_PATH_CHARTS_EDIT,
      },
      {
        component: ChartFormComponent,
        path: GC.ROUTE_PATH_CHARTS_NEW,
      },
      {
        component: CarrierEditComponent,
        path: GC.ROUTE_PATH_CARRIER_EDIT,
      },
      {
        component: CarrierProfile,
        path: GC.ROUTE_PATH_CARRIER_PROFILE,
      },
      {
        component: CarriersListComponent,
        path: GC.ROUTE_PATH_CARRIERS_LIST,
      },
      {
        component: CustomerMasterInvoicesComponent,
        path: GC.ROUTE_PATH_CUSTOMER_MASTER_INVOICE_LIST,
      },
      {
        component: DriverPayrollsComponent,
        path: GC.ROUTE_PATH_DRIVER_PAYROLL_LIST,
      },
      {
        component: LoadBoard,
        path: GC.ROUTE_PATH_LOAD_BOARD,
      },
      {
        component: NotificationListComponent,
        path: GC.ROUTE_PATH_NOTIFICATIONS,
      },
      {
        component: Imports,
        path: GC.ROUTE_PATH_IMPORT,
      },
      {
        path: GC.ROUTE_PATH_INTL,
        component: IntlComponent,
      },
      {
        path: GC.ROUTE_PATH_FUEL_CARDS,
        component: FuelCardListComponent,
      },
      {
        component: RouteListLoad,
        path: GC.ROUTE_PATH_DISPATCH_BOARD_TEL,
      },
      {
        path: GC.ROUTE_PATH_TOLL_CHARGES,
        component: TollChargesListComponent,
      },
      {
        component: RouteListOrder,
        path: GC.ROUTE_PATH_DISPATCH_BOARD_CLO,
      },
      {
        component: TemplateReport,
        path: GC.ROUTE_PATH_CONTACT_BOOK,
      },
      {
        component: NewDOComponent,
        path: GC.ROUTE_PATH_TEMPLATE_DO_DETAILS,
      },
      {
        component: LiteNewDOComponent,
        path: GC.ROUTE_PATH_LITE_TEMPLATE_DO_DETAILS,
      },
      {
        component: TemplateReport,
        path: GC.ROUTE_PATH_SHARED_ACCESSORIALS,
      },
      {
        component: AverageFuelPriceList,
        path: GC.ROUTE_PATH_AVERAGE_FUEL_PRICE,
      },
      {
        component: CarrierContractList,
        path: GC.ROUTE_PATH_CARRIER_CONTRACTS,
      },
      {
        component: CarrierContractDetails,
        path: GC.ROUTE_PATH_CARRIER_CONTRACT_DETAILS,
      },
      {
        component: CustomerContractDetails,
        path: GC.ROUTE_PATH_CUSTOMER_CONTRACT_DETAILS,
      },
      {
        component: CustomerContractList,
        path: GC.ROUTE_PATH_CUSTOMER_CONTRACTS,
      },
      {
        component: RatingGeoFencingZoneList,
        path: GC.ROUTE_PATH_GEO_FENCING_ZONE,
      },
      {
        component: ReportGeoFencingZoneList,
        path: GC.ROUTE_PATH_GEO_FENCING_ZONE_REPORT,
      },
      {
        component: DriversCardComponent,
        path: GC.ROUTE_PATH_DRIVERS_CARD,
      },
      {
        path: GC.ROUTE_PATH_ROUTE_LOAD,
        component: LoadDetailsNewComponent,
      },
      {
        path: GC.ROUTE_PATH_ROUTE_ORDER,
        component: OrderDetailsNewComponent,
      },
      {
        component: IftaListComponent,
        path: GC.ROUTE_PATH_IFTA_REPORT,
      },
      {
        component: DispatchGroupList,
        path: GC.ROUTE_PATH_DISPATCH_GROUP,
      },
      {
        path: GC.ROUTE_PATH_EDI_LIST,
        component: EDIIntegrationReport,
      },
      {
        component: CarrierEdiList,
        path: GC.ROUTE_PATH_CARRIER_EDI_LIST,
      },
      {
        component: PublicCLOList,
        path: GC.ROUTE_PATH_PUBLIC_CLO_LIST,
      },
      {
        component: NormalizeCurrencyList,
        path: GC.ROUTE_PATH_NORMALIZE_CURRENCY_LIST,
      },
      {
        component: CommissionAssignmentList,
        path: GC.ROUTE_PATH_COMMISSION_ASSIGNMENT,
      },
      {
        component: CarrierRatingReportList,
        path: GC.ROUTE_PATH_CARRIER_RATING_REPORT,
      },
      {
        component: CustomerPortal,
        path: GC.ROUTE_PATH_CUSTOMER_PORTAL,
      },
      {
        component: ImportReport,
        path: GC.ROUTE_PATH_IMPORT_REPORT,
      },
      {
        component: ChargeReport,
        path: GC.ROUTE_PATH_CLO_RATE_CHARGE_REPORT,
      },
      {
        component: ChargeReport,
        path: GC.ROUTE_PATH_TEL_RATE_CHARGE_REPORT,
      },
      {
        component: ServiceVendorList,
        path: GC.ROUTE_PATH_SERVICE_VENDOR_LIST,
      },
      {
        component: ServiceVendorProfile,
        path: GC.ROUTE_PATH_SERVICE_VENDOR_EDIT,
      },
      {
        component: RateShop,
        path: GC.ROUTE_PATH_RATE_SHOP,
      },
      {
        component: RouteMassCreateReport,
        path: GC.ROUTE_PATH_ROUTE_MASS_CREATE_REPORT,
      },
      {
        component: LosSubscriptionList,
        path: GC.ROUTE_PATH_LOS_SUBCRIPTION_LIST,
      },
      {
        component: AvailableDriverList,
        path: GC.ROUTE_PATH_AVAILABLE_DRIVER_LIST,
      },
      {
        component: LoadPlannerTemplateList,
        path: GC.ROUTE_PATH_LOAD_PLANNER_TEMPLATE_LIST,
      },
      {
        component: WorkOrderList,
        path: GC.ROUTE_PATH_WORK_ORDER_LIST,
      },
      // fleet-profile
      {
        component: FleetProfileDriver,
        path: GC.ROUTE_PATH_FLEET_PROFILE_DRIVER,
      },
      {
        component: FleetProfileVendor,
        path: GC.ROUTE_PATH_FLEET_PROFILE_VENDOR,
      },
      {
        component: FleetProfileTruck,
        path: GC.ROUTE_PATH_FLEET_PROFILE_TRUCK,
      },
      {
        component: FleetProfileTrailer,
        path: GC.ROUTE_PATH_FLEET_PROFILE_TRAILER,
      },
      // task-management
      {
        component: TaskManagementBoard,
        path: GC.ROUTE_PATH_TASK_MANAGEMENT_BOARD,
      },
      {
        component: TaskManagementReport,
        path: GC.ROUTE_PATH_TASK_MANAGEMENT_REPORT,
      },
      {
        component: TaskManagementBoardList,
        path: GC.ROUTE_PATH_TASK_MANAGEMENT_BOARD_LIST,
      },
      // claim-management
      {
        component: ClaimManagementReport,
        path: GC.ROUTE_PATH_CLAIM_MANAGEMENT_LIST,
      },
      {
        component: ClaimManagementClaimDetails,
        path: GC.ROUTE_PATH_CLAIM_MANAGEMENT_CLAIM_DETAILS,
      },
      {
        component: OAuthPopup,
        path: GC.ROUTE_PATH_OAUTH2_POPUP,
      },
      {
        component: InspectionsReport,
        path: GC.ROUTE_PATH_INSPECTIONS,
      },
      {
        component: UserTermsAndConditions,
        path: GC.ROUTE_PATH_USER_TERMS_AND_CONDITIONS,
      },
      // System Icons and Components
      {
        component: Icons,
        path: GC.ROUTE_PATH_DEV_ICONS,
      },
      {
        component: ComponentsStory,
        path: GC.ROUTE_PATH_DEV_COMPONENTS,
      },
    ],
  },
];
